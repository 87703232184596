import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240508-Rate-Sheet.pdf";
const date = "08.05.2024";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>
        <p>With effect from Wednesday 8 May 2024 we will be making the following changes to our range:</p>
        <p><strong>Summary of changes</strong></p>
        <p>We are pleased to be introducing a UK Buy To Let product with a &pound;3999 fee for 2 and 5 Year fixed rates for Purchase and Remortgage customers. This will be across all LTV&rsquo;s providing customers with more choice.</p>
        <p><strong>UK Residential Existing Customer Switching Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
            <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
            <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 90% LTV have increased</li>
        </ul>
        <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have increased</li>
            <li>10 Year Fixed Fee Saver at 60%, 70% 75% and 80% LTV have increased</li>
            <li>10 Year Fixed Standard at 60%, 70% 75% and 80% LTV have increased</li>
            <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 90% LTV have increased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
            <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
            <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
            <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        </ul>
        <p>There are no changes to any other interest rates.</p>
        <p>An updated mortgage rate sheet will be published on Wednesday 8 May 2024 to reflect these changes.</p>
    </NewsArticleLayout>
);

export default Article;